import uamServices from "@tvg/api/uam";
import { attempt, get } from "lodash";
import mediator from "@tvg/mediator";
                                      

import {
                   
  failUserPrefs,
  setUserPrefs
} from "../actions/login";

const getUserPreferences = (
  accountNumber        ,
  dispatch                       ,
  defaultPreferences         
) => {
  try {
    if (!accountNumber) {
      throw new Error("AccountNumber is required to get user preferences.");
    }

    uamServices.getUserPrefs(+accountNumber).then((res) => {
      const newPrefs = {};
            res.data?.preferences.forEach((pref) => {
        if (pref.description === "bet_prefs") {
          newPrefs[pref.description] = JSON.parse(pref.metadata);
        } else {
          newPrefs[pref.description] = pref.metadata;
        }
      });

      // load default payment method to storage
      attempt(() =>
        localStorage.setItem(
          "defaultPaymentMethod",
          get(newPrefs, `["defaultPaymentMethod"]`, null)
        )
      );

      dispatch(setUserPrefs(newPrefs, true));
      mediator.base.dispatch({ type: "USER_PREFS:LOAD_SUCCESS" });
    });
  } catch (err) {
    dispatch(failUserPrefs(err));
    mediator.base.dispatch({ type: "USER_PREFS:LOAD_FAIL" });

    if (typeof defaultPreferences === "object") {
      dispatch(setUserPrefs(defaultPreferences, false));
    }
  }
};

export default getUserPreferences;
