export const initialState = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  user: null,
  preferences: {},
  gotPreferences: false,
  gotPromos: false,
  loadingPromos: false,
  optedInPromos: {},
  wasLogin: false,
  balance: null,
  promoFunds: 0,
  balancePoller: 120,
  returningUser: false,
  brazeContentCards: {
    unviewed: 0,
    totalCards: 0,
    cards: []
  },
  isVerified: undefined,
  jurisdiction: undefined,
  isLoginError: false,
  sessionStartAt: undefined
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_BALANCE_SUCCESS": {
      return { ...state, ...action.payload };
    }

    case "USER_WALLET_BALANCES_SUCCESS": {
      return { ...state, ...action.payload };
    }

    case "USER_PROMO_FUNDS_SUCCESS": {
      return { ...state, ...action.payload };
    }

    case "USER_PREFS_SET": {
      return {
        ...state,
        preferences: action.payload.preferences,
        gotPreferences: action.payload.gotPreferences
      };
    }

    case "SET_IS_VERIFIED": {
      return { ...state, isVerified: action.payload.isVerified };
    }

    case "SET_USER_JURISDICTION": {
      return {
        ...state,
        jurisdiction: action.payload.jurisdiction
      };
    }

    case "USER_PROMOS_SUCCESS": {
      return {
        ...state,
        ...action.payload,
        gotPromos: true,
        loadingPromos: false
      };
    }

    case "USER_PROMOS_CLEAR": {
      return {
        ...state,
        optedInPromos: {},
        gotPromos: false,
        loadingPromos: false
      };
    }

    case "USER_PROMOS_LOADING": {
      return { ...state, loadingPromos: true };
    }

    case "USER_PROMOS_FAIL": {
      return { ...state, gotPromos: true, loadingPromos: false };
    }

    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged };
    }

    case "USER_LOGOUT": {
      return { ...initialState, logged: false, hasRequested: true };
    }

    case "USER_RETURNING_STATE": {
      return { ...state, returningUser: action.payload.returningUser };
    }

    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }

    case "USER_PREFS_BALANCE_SHOWN": {
      return {
        ...state,
        preferences: { ...state.preferences, balance_is_shown: action.payload }
      };
    }

    case "SET_DEFAULT_BET_PREFERENCES": {
      return {
        ...state,
        preferences: { ...state.preferences, bet_prefs: action.payload }
      };
    }

    case "USER_PREFS_BET_CONFIRMATION": {
      return {
        ...state,
        preferences: { ...state.preferences, show_bet_confirm: action.payload }
      };
    }

    case "SET_NEW_PREFERENCE_VALUE": {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.preferenceKey]: action.payload.preferenceValue
        }
      };
    }

    case "USER_BALANCE_POLLER": {
      return { ...state, balancePoller: action.payload };
    }

    case "BRAZE_TIMER_POLLER": {
      return { ...state, brazePoller: action.payload };
    }

    case "LOGIN_ERROR_PAGE": {
      return { ...state, isLoginError: action.payload.isLoginError };
    }

    case "USER_SESSION_START_UPDATE": {
      return {
        ...state,
        sessionStartAt: action.payload.sessionStartAt
      };
    }

    case "USER_PREFS_HOMEPAGE_TEMPLATE_DESKTOP": {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          homepageTemplateDesktop: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
