import { curry } from "./utils";
import Mediator from "./Mediator";
import MediatorNoop from "./MediatorNoop";

             
                    
                  
                   
                         

const createMediator = ()                    => {
  if (typeof window !== "undefined" && !window.mediator) {
    window.mediator = new Mediator();
    return window.mediator;
  }

  if (typeof window !== "undefined" && window.mediator) {
    return window.mediator;
  }

  return new MediatorNoop();
};

const instance                    = createMediator();

export const subscribe = curry(
  (name                 , handler                   ) =>
    instance.subscribe(name, handler)
);

export const subscribeWithPast = curry(
  (name                 , handler                   ) =>
    instance.subscribeWithPast(name, handler)
);

export const subscribeTimes = curry(
  (count        , name                 , handler                   ) =>
    instance.subscribeTimes(count, name, handler)
);

export const subscribeOnce = subscribeTimes(1);
export const subscribeTwice = subscribeTimes(2);
export const subscribeThrice = subscribeTimes(3);

export const unsubscribe = curry(
  (name                 , handler                   ) =>
    instance.unsubscribe(name, handler)
);

export const dispatch = curry((type                 , value   ) =>
  instance.dispatch(type, value)
);

export const query = (type                 ) => instance.query(type);
