import React from "react";
import { Button } from "../../../../../src";
import {
  PickBetsContainer,
  PickBetsLinkContainer,
  PickBetsTextWrapper
} from "./styled-components";
import { Paragraph } from "../../../Typography";
import { type PickBetRebetProps } from "../../types";

const PickBetRebet = ({
  shouldShowRebet,
  isContentDisplayed,
  rebetWagerTypeName,
  onClickBet
}: PickBetRebetProps) =>
  shouldShowRebet ? (
    <PickBetsContainer
      data-qa-label="pick-bets-container"
      hasRoundBottomBorder={!isContentDisplayed}
    >
      <PickBetsTextWrapper>
        <Paragraph
          fontFamily="medium"
          color="--fd-colors-content-default"
          qaLabel="pick-bets-rebet-title"
        >{`${rebetWagerTypeName} Available`}</Paragraph>
        <Paragraph qaLabel="pick-bets-rebet-description" fontSize="xs">
          Bet using your remaining selections
        </Paragraph>
      </PickBetsTextWrapper>
      <PickBetsLinkContainer data-qa-label="myBets-pickbets-link">
        <Button
          variant="betting"
          iconPosition="end"
          icon="arrowRight"
          onPress={onClickBet}
          qaLabel="button"
          size="m"
          isStretched
        >
          Create Bet
        </Button>
      </PickBetsLinkContainer>
    </PickBetsContainer>
  ) : null;

export default PickBetRebet;
