import DownloadButton from "@tvg/account-transactions/src/DownloadButton";

const PagesConfig = [
  {
    key: "/results",
    header: {
      title: "Results",
      logoMod: "navigation",
      isActionToolsShown: false,
      isLogActionsShown: false
    }
  },
  {
    key: "/responsible-gaming/funding-controls",
    header: {
      title: "Funding Controls",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/responsible-gaming",
      headerClass: "monochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/responsible-gaming/deposit-limits",
    header: {
      title: "Deposit Limits",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/responsible-gaming",
      headerClass: "monochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/responsible-gaming/timeout",
    header: {
      title: "Timeout & Exclusions",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/responsible-gaming",
      headerClass: "monochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/responsible-gaming/self-exclude",
    header: {
      title: "Timeout & Exclusions",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/responsible-gaming",
      headerClass: "monochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/responsible-gaming/suspend-account",
    header: {
      title: "Timeout & Exclusions",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/responsible-gaming",
      headerClass: "monochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/responsible-gaming",
    header: {
      title: "Responsible Gaming",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/more"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/racetracks/:trackAbbr?/:trackName?",
    queryParameters: "race=:raceId?",
    header: {
      logoMod: "logo",
      isActionToolsShown: true
    }
  },
  {
    key: "/referral",
    header: {
      title: "Refer a Friend",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/more"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/wager-rewards",
    header: {
      title: "Wager Rewards",
      logoMod: "navigation",
      isActionToolsShown: false,
      isLogActionsShown: false
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/promos/:slug?/:id?",
    header: {
      logoMod: "logo",
      isActionToolsShown: true
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/promos/:slug?",
    header: {
      logoMod: "logo",
      isActionToolsShown: true
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/redirectEngine",
    header: {
      title: "Redirect Engine",
      logoMod: "navigation",
      isActionToolsShown: false,
      isVisible: false
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/more",
    header: {
      logoMod: "logo",
      isActionToolsShown: true,
      isLogActionsShown: true,
      isVisible: false
    }
  },
  {
    key: "/my-account-summary",
    header: {
      title: "Account Summary",
      logoMod: "navigation",
      isActionToolsShown: false,
      prevPath: "/more",
      childComponent: DownloadButton,
      showChildComponentProp: "showDownloadAccountTransactions"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/credentials-reset",
    header: {
      logoMod: "logo",
      isActionToolsShown: false,
      isLogActionsShown: false,
      headerClass: "logo-highlighted"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/wallet",
    header: {
      title: "Wallet",
      logoMod: "navigation",
      isActionToolsShown: false,
      isLogActionsShown: false,
      headerClass: "lowercaseMonochromatic"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/preferences/talent-alerts",
    header: {
      title: "Preferences",
      isActionToolsShown: false,
      isLogActionsShown: false,
      logoMod: "navigation",
      prevPath: "/preferences",
      headerClass: "lowercaseMonochromatic",
      isTitleCenter: true,
      description:
        "Get notified when your favorite talent has a new pick available to bet."
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/preferences",
    header: {
      title: "Preferences",
      isActionToolsShown: false,
      isLogActionsShown: false,
      logoMod: "navigation",
      prevPath: "/more",
      headerClass: "lowercaseMonochromatic",
      isTitleCenter: true
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/unsubscribe",
    header: {
      logoMod: "logo",
      prevPath: "/"
    },
    footer: {
      isVisible: false
    }
  },
  {
    key: "/registration",
    header: {
      isVisible: false
    },
    footer: {
      isVisible: false
    }
  }
];

export default PagesConfig;
