import React, {
  cloneElement,
  isValidElement,
  useRef,
  MutableRefObject,
  useEffect,
  forwardRef
} from "react";
import type { TooltipDescriptiveContent } from "./types";
import { Header, Paragraph } from "../Typography";
import { Wrapper as StyledWrapper } from "./styled-components";
import { usePopper } from "../../../src/components/popper";

const Content = forwardRef<HTMLDivElement | null, TooltipDescriptiveContent>(
  (
    {
      placement,
      header,
      body,
      actionLink,
      isDarkMode,
      closeDelay,
      forceClose,
      widthContent = "284px",
      ...rest
    },
    ref
  ) => {
    const timeoutRef: MutableRefObject<number | undefined> = useRef();
    const { isOpen, closeContent } = usePopper();

    useEffect(() => {
      if (forceClose) {
        closeContent();
      }

      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(closeContent, closeDelay);
      }

      if (!isOpen && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [isOpen, forceClose]);

    return (
      <StyledWrapper
        ref={ref}
        {...rest}
        placement={placement}
        backgroundColor={
          !isDarkMode
            ? "var(--fd-colors-brand-secondary-shade)"
            : "var(--fd-colors-background-layer)"
        }
        p="space-4"
        width={widthContent}
      >
        {header &&
          (isValidElement(header) ? (
            cloneElement(header)
          ) : (
            <Header
              qaLabel="tooltip-descriptive-header"
              tag="h3"
              marginBottom="space-3"
              color={
                isDarkMode
                  ? "--fd-colors-content-strong"
                  : "--fd-colors-content-on-dark"
              }
            >
              {header}
            </Header>
          ))}
        {isValidElement(body) ? (
          cloneElement(body)
        ) : (
          <Paragraph
            qaLabel="tooltip-descriptive-body"
            color={
              isDarkMode
                ? "--fd-colors-content-default"
                : "--fd-colors-core-white"
            }
          >
            {body}
          </Paragraph>
        )}
        {actionLink && isValidElement(actionLink) && cloneElement(actionLink)}
      </StyledWrapper>
    );
  }
);

export default Content;
