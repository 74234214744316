import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  HomePageLoadable,
  TracksPageLoadable,
  ResultsPageLoadables,
  ProgramPageLoadable,
  PicksPageLoadable,
  LivePageLoadable,
  MorePageLoadable,
  WagerRewardsLoadable,
  RGLoadable,
  RGFundingControlsLoadable,
  RGTimeoutLoadable,
  RgDepositLimitsLoadable,
  TrackListPageLoadable,
  PromosPageLoadable,
  ReferPageLoadable,
  AccountSummaryLoadable,
  RegistrationLoadable,
  ResetCredentialsLoadable,
  WalletLoadable,
  PreferencesPageLoadable,
  PreferencesTalentAlertsPageLoadable,
  EmailUnsubscribePageLoadable
} from "./Loadables";
import RoutesFile from "./Routes";
// eslint-disable-next-line
import RedirectPage from "./components/RedirectPage";

const ComponentMap = {
  home: HomePageLoadable,
  live: LivePageLoadable,
  trackList: TrackListPageLoadable,
  programPage: ProgramPageLoadable,
  programPageOld: ProgramPageLoadable,
  liveProgramPage: ProgramPageLoadable,
  liveProgramPageOld: ProgramPageLoadable,
  programPageGreyhounds: ProgramPageLoadable,
  programPageGreyhoundsOld: ProgramPageLoadable,
  tracks: TracksPageLoadable,
  results: ResultsPageLoadables,
  picks: PicksPageLoadable,
  more: MorePageLoadable,
  wagerRewards: WagerRewardsLoadable,
  promosPage: PromosPageLoadable,
  responsibleGaming: RGLoadable,
  RGFundingControls: RGFundingControlsLoadable,
  rgSelfExclude: RGTimeoutLoadable,
  rgSuspendAccount: RGTimeoutLoadable,
  rgTimeout: RGTimeoutLoadable,
  optedin: HomePageLoadable,
  rgDepositLimits: RgDepositLimitsLoadable,
  referFriendPage: ReferPageLoadable,
  accountSummary: AccountSummaryLoadable,
  registration: RegistrationLoadable,
  resetCredentials: ResetCredentialsLoadable,
  wallet: WalletLoadable,
  preferences: PreferencesPageLoadable,
  preferencesTalentAlerts: PreferencesTalentAlertsPageLoadable,
  emailUnsubscribe: EmailUnsubscribePageLoadable,
  default: HomePageLoadable,
  redirect: RedirectPage
};

export const PageRoutes = (toggles) =>
  RoutesFile.filter((route) =>
    route.toggle ? toggles[route.toggle] : true
  ).map((route) => ({ ...route, component: ComponentMap[route.key] }));

/* eslint-disable */
export default (props) => (
  <Routes location={props.location}>
    {PageRoutes(props.toggles)
      .map(
        (Page) =>
          Page.component && (
            <Route
              key={Page.key}
              path={Page.path}
              element={
                <Page.component
                  location={props.location}
                  history={props.history}
                  transitionState={props.state}
                  prevPath={props.prevPath}
                  isLogged={props.isLogged}
                  device={props.device}
                  product={props.product}
                  isBeta={props.isBeta}
                  isFixed={props.isFixed}
                  featureToggles={props.toggles}
                />
              }
            />
          )
      )
      .filter(Boolean)}
    {/* $FlowFixMe */}
    <Route path="*" element={<RedirectPage />} />
  </Routes>
);
/* eslint-enable */
