import React from "react";
import type { EventHandler, SyntheticEvent } from "react";
import {
  info,
  warningDiamond,
  hexagon,
  successOutline,
  cross
  // @ts-ignore
} from "@fdr/static-ui/Icons/icons";
import {
  Title,
  Text,
  MessageWrapper,
  ContentWrapper,
  TextWrapper,
  ButtonLink,
  StyledIcon,
  CloseIcon
} from "./styled-components";
import {
  LinkAction,
  BaseProps,
  FixedBannerProps,
  FloatingBannerProps,
  FixedMessageProps,
  InlineMessageProps
} from "./types";

const MessageTypeIcon = {
  info,
  warning: warningDiamond,
  success: successOutline,
  error: hexagon
};

const renderMessageBox = ({
  qaLabel = "message-box",
  title = "",
  text = "",
  type = "info",
  onClose,
  isFixed = false,
  isInline = false,
  isBorderLess = false,
  hasIcon = true,
  isMessageCenter = false,
  link,
  children
}: BaseProps & {
  isFixed?: boolean;
  isInline?: boolean;
  isBorderLess?: boolean;
  onClose?: EventHandler<SyntheticEvent>;
  link?: LinkAction;
}) => (
  <MessageWrapper
    data-qa-label={qaLabel}
    messageType={type}
    isFixed={isFixed}
    isBorderLess={isBorderLess}
    isMessageCenter={isMessageCenter}
  >
    <ContentWrapper>
      {hasIcon && (
        <StyledIcon
          qaLabel={`${qaLabel}-type-icon`}
          icon={MessageTypeIcon[type]}
          messageType={type}
          isFixed={isFixed}
        />
      )}
      <TextWrapper
        deleteMargin={!hasIcon}
        isInline={isInline}
        isCloseable={typeof onClose === "function"}
        hasTitle={!!title}
      >
        {title && (
          <Title data-qa-label={`${qaLabel}-title`}>{`${title}`}</Title>
        )}
        {text && (
          <Text
            data-qa-label={`${qaLabel}-text`}
            dangerouslySetInnerHTML={{ __html: text }}
            hasElementBefore={isInline && !!title}
            isInline={isInline}
          />
        )}
        {link && (
          <ButtonLink
            data-qa-label={`${qaLabel}-link`}
            to={link.url}
            onClick={link.onClick}
            messageType={type}
            isFixed={isFixed}
            isInline={isInline}
          >
            {link.text}
          </ButtonLink>
        )}
      </TextWrapper>
      {typeof onClose === "function" && (
        <CloseIcon data-qa-label={`${qaLabel}-close`} onClick={onClose}>
          <StyledIcon
            qaLabel={`${qaLabel}-close-icon`}
            icon={cross}
            messageType={type}
            isFixed={isFixed}
          />
        </CloseIcon>
      )}
    </ContentWrapper>
    {children}
  </MessageWrapper>
);

export const FixedMessage = (props: FixedMessageProps) =>
  renderMessageBox({
    ...props,
    isFixed: true,
    isInline: false,
    isBorderLess: true
  });

export const InlineMessage = (props: InlineMessageProps) =>
  renderMessageBox({
    ...props,
    isFixed: false,
    isBorderLess: true
  });

export const FixedBanner = (props: FixedBannerProps) =>
  renderMessageBox({
    ...props,
    isFixed: true,
    isInline: true,
    isBorderLess: true
  });

export const FloatingBanner = (props: FloatingBannerProps) =>
  renderMessageBox({
    ...props,
    isFixed: false,
    isInline: false,
    isBorderLess: false
  });

export default FixedMessage;
