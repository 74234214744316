import { get } from "lodash";
import pro from "@tvg/api/pro";
import TVGConf from "@tvg/conf";
import mediator from "@tvg/mediator";
                                                                        
import {
  clearUserPromos,
  successUserPromos,
  failUserPromos,
  loadingUserPromos
} from "../actions/login";
                                                    

// TODO: this should be on the tvg-api
export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

export const transformUserOptedInPromos = (
  promos                
)                    => {
  const optedInPromos = {};
  if (promos)
    promos.forEach((promo) => {
      optedInPromos[promo.id] = promo.optedIn;
    });

  return optedInPromos;
};

export const clearOptedInPromos = (dispatch                       ) =>
  dispatch(clearUserPromos());

export default (dispatch                       , userId        ) => {
  dispatch(loadingUserPromos());
  return pro
    .fetchAllEligiblePromos(userId)
    .then((response) => {
      const userOptedInPromos = transformUserOptedInPromos(
        get(response, "data.promotions", [])
      );
      dispatch(successUserPromos(userOptedInPromos));
      mediator.base.dispatch({
        type: "TVG_LOGIN:UPDATE_USER_PROMOS",
        payload: userOptedInPromos
      });
    })
    .catch((err) => dispatch(failUserPromos(err)));
};
