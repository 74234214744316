export const initialState = {
  raceAlerts: []
};

export default function alertsReducer(
  state        = initialState,
  action                         
) {
  switch (action.type) {
    case "SET_RACE_ALERTS": {
      return { ...state, raceAlerts: action.payload.channels };
    }

    default: {
      return state;
    }
  }
}
