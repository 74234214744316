import { curry, get, memoize } from "lodash";
import { replaceAll } from "@tvg/utils/generalUtils";
import uamService from "@tvg/api/uam";

import queryString from "query-string";

import tvgConf from "@tvg/conf";

export const backOff = curry(function backOffFn(fn, timeout) {
  let prevInterval = 0;
  let nextInterval = 1000;
  const checkTimedOut = curry(
    function checkTimedOutFn(timeoutMax, startTime, currentTime) {
      return currentTime - startTime > timeoutMax;
    }
  );
  const hasTimedOut = checkTimedOut(timeout, +new Date());

  return new Promise(function promiseFn(resolve, reject) {
    function stop(arg) {
      return arg instanceof Error ? reject(arg) : resolve(arg);
    }

    function next() {
      const tmp = nextInterval;

      if (!hasTimedOut(+new Date())) {
        setTimeout(() => fn(next, stop), nextInterval);
        nextInterval += prevInterval;
        prevInterval = tmp;
      } else {
        reject(new Error("BACKOFF_TIMEOUT"));
      }
    }

    fn(next, stop);
  });
});

export const getAddressStreetNumber = ({ address } = {}) => {
  const streetNumberMatch =
    address && address.street && address.street.match(/\d+/);
  const streetNumber = streetNumberMatch && streetNumberMatch[0];
  return streetNumber || "";
};

export const buildUserDetails = ({
  welcomeFields,
  addressFields,
  firstFiveDigits = "",
  ssnFields,
  selectedPromo,
  selectedTvProvider
}) => ({
  userDetails: {
    username: welcomeFields.email,
    password: welcomeFields.password,
    email: welcomeFields.email,
    firstName: welcomeFields.firstName,
    lastName: welcomeFields.lastName,
    dateOfBirth: replaceAll(welcomeFields.birth, " ", "-"),
    homeAddress: {
      streetNumber: getAddressStreetNumber({ address: addressFields.home }),
      address1: addressFields.home.street,
      address2: addressFields.home.details,
      zipCode: addressFields.home.zip,
      city: addressFields.home.city,
      state: addressFields.home.state,
      country: "United States"
    },
    mailAddress: {
      streetNumber: getAddressStreetNumber({
        address: addressFields.mailing
      }),
      address1: addressFields.mailing.street,
      address2: addressFields.mailing.details,
      zipCode: addressFields.mailing.zip,
      city: addressFields.mailing.city,
      state: addressFields.mailing.state,
      country: "United States"
    },
    sameMailingAddress: !addressFields.hasMailingAddress,
    socialSecurityNumber: firstFiveDigits + ssnFields.ssn.join(""),
    phone: welcomeFields.phone.replace(/[-() ]/g, ""),
    subscriptions: {
      email: true
    },
    signalProvider: {
      name: get(selectedTvProvider, "key", ""),
      providerId: get(selectedTvProvider, "value", "")
    }
  },
  signupDetails: {
    promoCode: selectedPromo.promoReference,
    campaignCode: "",
    rfr: document.cookie.match(`(^|;)\\s*RFR\\s*=\\s*([^;]+)`)?.pop() || ""
  }
});

export const buildUrlWelcomeFields = (redirectUrl, welcomeFields) => {
  if (!welcomeFields) {
    return encodeURI(`https://${redirectUrl}${location.search}`);
  }
  const { password = "", ...welcomeFieldsCpy } = welcomeFields;
  const welcomeFieldsString = JSON.stringify(welcomeFieldsCpy);

  const params = queryString.parse(location.search);
  if (params.welcomeFields) {
    delete params.welcomeFields;
  }

  const newQueryParams =
    Object.keys(params).length > 0
      ? `?${queryString.stringify(params)}&welcomeFields=${welcomeFieldsString}`
      : `?welcomeFields=${welcomeFieldsString}`;

  return encodeURI(`https://${redirectUrl}${newQueryParams}`);
};

export const getZipValidationMemo = memoize(uamService.getZipCodeValidation);

export const getStateValidationMemo = memoize(uamService.getStateValidation);
