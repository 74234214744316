import geocomplyReducer, {
  initialState as geoComplyInitalState } from "@tvg/sh-geolocation/src/redux/reducers";
                                                 

                     
             
                      
                      
    
            
                        
               
                 
                
      
               
                   

export const initialState = {
  location: {
    latitude: null,
    longitude: null
  },
  regions: [
    {
      description: "",
      id: 0,
      name: "Default",
      type: "Default"
    }
  ],
  state: "",
  ...geoComplyInitalState
};

const geoLocationReducer = (
  state        = initialState,
  action            
) => {
  switch (action.type) {
    case "GEO_LOCATION_GET": {
      return { ...state, ...action.payload };
    }
    case "GEO_LOCATION_SET_STATE": {
      return { ...state, ...action.payload };
    }
    case "GEO_LOCATION_RESET": {
      return { ...initialState };
    }
    case "GEO_SHOW_STATE_SELECTOR": {
      return { ...state, showStateSelector: action.payload };
    }
    default: {
      // Please use GeoComplyReducer directly when the old geo location when it is removed.
      return geocomplyReducer(state, action);
    }
  }
};

export default geoLocationReducer;
