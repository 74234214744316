import styled from "styled-components";

export const SeoContainer = styled.div`
  background-color: var(--fd-colors-core-white);
  padding: 15px 12px 20px;

  a {
    vertical-align: baseline;
    color: var(--fd-colors-core-blue);
    cursor: pointer;
  }

  p {
    padding-top: 12px;
    color: var(--fd-colors-core-grey);
  }
`;
