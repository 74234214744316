import { attempt } from "lodash";
import { v4 as uuid } from "uuid";
import mediator from "@tvg/mediator";
import getUserPromos from "../services/optedInPromos";
import { setUserAccountNumber } from "../actions/login";

              
                    
                        
                         
                       
  

export const manageEquibaseId = (toAdd         ) => {
  if (toAdd) {
    if (!attempt(() => window.localStorage.getItem("equibaseId"))) {
      attempt(() => window.localStorage.setItem("equibaseId", uuid()));
    }
  } else if (attempt(() => window.localStorage.getItem("equibaseId"))) {
    attempt(() => window.localStorage.removeItem("equibaseId"));
  }
};

export const promosSubscriber = ({
  isLogged,
  accountNumber,
  loadingPromos,
  dispatch
}       ) => {
  if (isLogged && accountNumber && accountNumber !== "" && !loadingPromos) {
    getUserPromos(dispatch, accountNumber);
  }
};

export const controllerDidMountCommon = (dispatch             ) => {
  // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
  // this is useful because every component initialized will know if the user is logged, plus it's
  // account number beforehand

  let callback = null;
  attempt(() => {
    if (window.sessionStorage.getItem("userId")) {
      dispatch(setUserAccountNumber(window.sessionStorage.getItem("userId")));
    }
  });

  mediator.base.subscribe("SET_CALLBACK", (data) => {
    if (typeof data.payload === "function") {
      callback = data.payload;
    }
  });

  mediator.base.subscribe("TRIGGER_CALLBACK", () => {
    if (typeof callback === "function") {
      callback();
    }

    // Reset it again
    mediator.base.dispatch({
      type: "CLEAR_CALLBACK"
    });
  });

  mediator.base.subscribe("CLEAR_CALLBACK", () => {
    callback = null;
  });
};
