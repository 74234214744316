import _ from "lodash";

// face id

export const initialState = {
  userChangingTouchId: false,
  isNotificationsEnabled: false,
  touchId: {
    accountId: "",
    token: "",
    touchIdEnabled: false,
    touchIdType: 0
  },
  touchIdError: {
    errorCode: 0,
    message: ""
  },
  init: {
    type: "",
    message: "",
    appVersion: "",
    url: "",
    touchIdAvailable: false,
    touchIdEnabled: false,
    touchIdType: 0,
    accountId: 0,
    launchTimestamp: 0,
    loadTimestamp: 0,
    error: "",
    meta: ""
  }
};

const iOSEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IOS_INIT_RESPONSE": {
      const init = _.merge(state.init, action.payload);
      return {
        ...state,
        init,
        touchId: {
          ...state.touchId,
          accountId: init.accountId,
          touchIdEnabled: init.touchIdEnabled,
          touchIdAvailable: init.touchIdAvailable,
          touchIdType: init.touchIdType
        }
      };
    }
    case "IOS_TOUCH_ID_RESPONSE": {
      const touchId = _.merge(state.touchId, action.payload);
      return { ...state, touchId };
    }
    case "IOS_TOUCH_ID_ERROR": {
      const touchIdError = _.merge(state.touchIdError, action.payload);
      return { ...state, touchIdError };
    }
    case "IOS_TOUCH_ID_TOGGLE": {
      return {
        ...state,
        touchId: { ...state.touchId, touchIdEnabled: action.payload }
      };
    }
    case "IOS_TOUCH_ID_CHANGE": {
      return {
        ...state,
        userChangingTouchId: action.payload.isChanging
      };
    }
    case "IOS_NOTIFICATIONS_STATE": {
      return {
        ...state,
        isNotificationsEnabled: action.payload.isNotificationsEnabled
      };
    }
    default: {
      return state;
    }
  }
};

export default iOSEventsReducer;
