export const openLoginModal = (payload                           ) => ({
  type: "OPEN_LOGIN_MODAL",
  payload
});

export const closeLoginModal = ()                        => ({
  type: "CLOSE_LOGIN_MODAL"
});

export const resetLoginFlow = () => ({ type: "RESET_LOGIN_FLOW" });

export const resetLoginFlowStatus = () => ({ type: "RESET_LOGIN_FLOW_STATUS" });

export const openErrorModal = ()                   => ({
  type: "OPEN_ERROR_MODAL"
});
