import { get } from "lodash";
import * as amplitude from "@amplitude/analytics-browser";

import {
  BaseEventProps,
  ExtraPropertiesArgs,
  GetGlobalProperties,
  GlobalProperties,
  PathnameHashAndSearch
} from "./types";
import { getPageName, getPageViewVal } from "./utils";
import { URP_PAGE_CONFIG } from "./constants";

export default (
  getGlobalProperties: GetGlobalProperties,
  pagesConfig = URP_PAGE_CONFIG,
  extra?: ExtraPropertiesArgs
) => {
  const name = "global-properties-plugin";
  const type: amplitude.Types.EnrichmentPlugin<
    amplitude.Types.CoreClient,
    amplitude.Types.Config
  >["type"] = "enrichment";
  const setup = async function () {
    // initial setup here!
  };

  // The following function will "intercept" every event being triggered
  // and will append the "global properties" to every event
  const execute = async function (event: amplitude.Types.Event) {
    const COMMON_EVENT_NAMES = {
      SITE_CLICK: "SITE CLICK",
      PAGE_VIEWED: "PAGE VIEWED",
      NAVIGATED: "NAVIGATION LINK CLICKED",
      OPEN: "OPENED",
      CLOSE: "CLOSED",
      DELETED: "DELETED"
    };

    const globalProperties =
      typeof getGlobalProperties === "function" && getGlobalProperties();
    if (globalProperties) {
      event.event_properties ||= {};
      event.event_properties = {
        ...globalProperties,
        ...event.event_properties
      };
    }

    const eventType = event.event_type.toUpperCase();
    if (eventType === COMMON_EVENT_NAMES.PAGE_VIEWED) {
      const pageName = get(
        event.event_properties,
        GlobalProperties.PAGE_NAME,
        getPageName(window.location, pagesConfig)
      );

      event.event_type = getPageViewVal(pageName);
    }

    const fullUrl: string = get(
      event.event_properties,
      GlobalProperties.FULL_URL,
      ""
    );
    const urlObj = new URL(fullUrl);
    const prevPath = extra?.prevPath.current;
    const ogPath = get(event.event_properties, GlobalProperties.PAGE_PATH, "");
    const linkUrl: string = get(
      event.event_properties,
      BaseEventProps.LINK_URL
    );

    const hasNavigated = linkUrl && linkUrl.includes(ogPath);

    const hasChangedTab = eventType.includes("TAB");
    const isModalOpen = !!urlObj.hash;
    const hasOpenedModal =
      (eventType.includes(COMMON_EVENT_NAMES.OPEN) ||
        eventType.includes(COMMON_EVENT_NAMES.NAVIGATED)) &&
      isModalOpen &&
      !hasChangedTab;

    // if the event is processed after navigation/opened, we need to set the properties accordingly to previous path
    if ((hasNavigated || hasOpenedModal) && prevPath) {
      const prevFullUrl = hasOpenedModal
        ? `${urlObj.origin}${prevPath}`
        : fullUrl.replace(ogPath, prevPath);

      event.event_properties = {
        ...event.event_properties,
        // Ensure that the LINK_URL is an absolute URL
        ...(hasNavigated || linkUrl
          ? { [BaseEventProps.LINK_URL]: fullUrl }
          : {}),
        [GlobalProperties.FULL_URL]: prevFullUrl,
        [GlobalProperties.PAGE_PATH]: prevPath,
        [GlobalProperties.PAGE_NAME]: getPageName(
          { pathname: prevPath } as PathnameHashAndSearch,
          pagesConfig
        )
      };
    }

    return event;
  };

  const plugin: amplitude.Types.EnrichmentPlugin = {
    name,
    setup,
    type,
    execute
  };

  return plugin;
};
