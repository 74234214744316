import React from "react";
import TVGConf from "@tvg/conf";
import Header from "@tvg/atomic-ui/_templates/Header";
import mediator from "@tvg/mediator";
import { events as AlchemerEvents } from "@urp/alchemer";
import { get } from "lodash";

export const onLogoClick = () => {
  mediator.base.dispatch({
    type: "LOGO:HOME_NAVIGATION"
  });
  AlchemerEvents.selectTVGLogo();
};

export const onBackClick = (prevPath, title = "") => {
  if (prevPath) {
    const destinationUrl = `${get(window, "location.host")}${prevPath}`;
    mediator.base.dispatch({
      type: "HEADER_GO_BACK",
      payload: {
        gaEventLabel: "back to",
        locationPathname: get(window, "location.pathname"),
        destinationUrl
      }
    });
  }

  if (title.toLowerCase() === "wallet") {
    mediator.base.dispatch({
      type: "PAWS:WALLET_GO_BACK"
    });
  }
};

export const openInbox = (open, unviewedContentCardsCount) => {
  mediator.base.dispatch({
    type: "OPEN_CLOSE_INBOX",
    payload: {
      open,
      unviewedContentCardsCount
    }
  });
};

export const HeaderComponent = (props) => {
  const raceData = {
    mtp: get(props, "currentRace.mtp"),
    postTime: get(props, "currentRace.postTime"),
    status: get(props, "currentRace.status.code"),
    trackAbbr: get(props, "currentRace.trackCode"),
    raceNumber: get(props, "currentRace.number"),
    trackName: get(props, "currentRace.track.shortName")
  };

  return (
    <Header.TVG
      logoMod={props.logoMod}
      videoFeedBack={props.videoFeedback}
      onVideoButton={props.onVideoButton}
      isNavigationShown={props.isNavigationShown}
      isVideoButtonShown={props.isVideoButtonShown}
      openInbox={openInbox}
      openRaceNavigation={() => {
        mediator.base.dispatch({
          type: "PPM_OPEN_RACE_NAVIGATION",
          payload: {
            trackName: get(props, "currentRace.track.shortName"),
            raceNumber: get(props, "currentRace.raceNumber"),
            module: "Header"
          }
        });
        props.openRaceNavigation();
      }}
      isLogged={props.isLogged}
      isRaceDataShown={props.isRaceDataShown}
      isActionToolsShown={get(
        props,
        "isActionToolsShown",
        props.logoMod !== "navigation"
      )}
      isLogActionsShown={get(
        props,
        "isLogActionsShown",
        props.logoMod !== "navigation"
      )}
      onLogoClick={onLogoClick}
      quickDeposit={{
        balance: props.balance,
        isBalanceShown: props.isBalanceShown,
        url: props.quickDepositUrl,
        onClick: () =>
          mediator.base.dispatch({
            type: "DEPOSITS_OPEN_QUICK",
            payload: { module: "Header" }
          })
      }}
      brand={TVGConf().brand}
      loginSignup={{
        signUpUrl: props.signUpUrl,
        loginUrl: props.loginUrl
      }}
      raceData={raceData}
      layout={props.layout}
      onBackClick={() => onBackClick(props.prevPath, props.title)}
      prevPath={props.prevPath}
      label={props.title}
      unviewedContentCardsCount={props.unviewedContentCardsCount}
      isContentCardsInboxShown={props.isContentCardsInboxShown}
      headerClass={props.headerClass}
      isTitleCenter={props.isTitleCenter}
      description={props.description}
    >
      {props.children}
    </Header.TVG>
  );
};

export default HeaderComponent;
